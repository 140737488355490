<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn plain v-bind="attrs" v-on="on" fab alt="Ver">
        <v-icon medium class="mr-2">
          mdi-eye
        </v-icon>
      </v-btn>
    </template>

    <v-card color="white">
      <v-card-title class="secondary--text">

        <v-row justify="start" align="center">
          <v-col cols="3">
            <v-chip class="ma-2 white--text" color="blue" black label>
              Sugestão #{{ currentItem.id }}
            </v-chip>
          </v-col>
          <v-col cols="8" class="text-left" v-if="currentItem.usuario.text">
            {{ currentItem.usuario.text }}
            <v-avatar size="56">
              <v-img :src="currentItem.avatar"></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="1">
            <v-btn outlined fab rounded small dark @click="dialog = false" color="red">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-container>
          <v-card>
            <v-card-title>
              {{ currentItem.titulo }}
            </v-card-title>
            <v-card-text class="text-justify pl-15 pr-10">
              <v-row>
                <!-- <v-col cols="2" class="font-weight-bold text-right">Sugestão:</v-col> -->
                <v-col>
                  {{ currentItem.sugestao }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mt-5">
            <v-card-title>
              Detalhes
            </v-card-title>
            <v-card-text class="ml-5">
              <v-row dense v-if="currentItem.avaliador" class="ma-0 pa-0">
                <b>Avaliador:</b> &nbsp; {{ currentItem.avaliador.text }}
              </v-row>
              <v-row dense class="ma-0 pa-0">
                <b>Criado em: </b> &nbsp; {{ currentItem.created_at | formatData }}
              </v-row>

              <v-row class="ma-0 pa-0"><b>Criado por:</b> &nbsp; {{ config.usuario_mv }}</v-row>

              <v-row v-if="currentItem.movimentos.length > 0">
                <v-col cols="2" class="font-weight-bold text-right">Resultado:</v-col>
                <v-col>

                  <v-timeline align-top dense>
                    <v-timeline-item small v-for="i in currentItem.movimentos" :key="`${i.id}m`"
                      :color="i.resultado.cor" right>
                      <v-chip label outlined :color="i.resultado.cor" class="mb-1">
                        {{ i.resultado.text }}
                      </v-chip>
                      <div class="caption">
                        <b>Atualizado em:</b> {{ i.created_at | formatData }}
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-col>
              </v-row>
            </v-card-text>

          </v-card>

        </v-container>
      </v-card-text>
      <br>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Sugestao',
  props: {
    currentItem: { type: Object }
  },
  data() {
    return {
    dialog: false,
    config: JSON.parse(localStorage.getItem('cfgu'))
  }
}
}
</script>